/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/jquery.validation/index.d.ts' />

// Allow validation of a subset of fields
(<any>$.validator).prototype.subset = function (container) {
    var ok = true;
    var validator = this;
    $(container).find(':input:not([type="hidden"]):not(:disabled), :input[type="hidden"].validate-subset-hidden').each((_index, element) => {
        if (!validator.element($(element))) {
            ok = false;
        }
    });
    return ok;
}